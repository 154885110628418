<template>
  <v-main :style="drawer ? 'padding: 75px 0px 0px 56px;' : 'padding: 75px 0px 0px;'">
    <router-view />
  </v-main>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'DashboardCoreView',
    computed: {
      ...mapState({
        drawer: state => state.settings.drawer,
        notifikasi: state => state.user.notifikasi,
        avatar: state=> state.user.avatar,
        username: state=> state.user.current.username,
        user: state=> state.user.dataPegawai,
        current: state=> state.user.current
      }),
    },
  }
</script>
